import React, { useContext, useEffect, useState } from 'react'
import CustomWrapper from '../../wrapper/CustomWrapper'
import PopupWrapper from '../../wrapper/PopupWrapper'
import Button from '../../Button/Button'
import image from "../../../assets/checkinAccount.jpeg"
import ErrorPng from "../../../assets/error.png";
import { FormException } from '../../../utility/exceptions'
import { addPaymentMethod, deletePaymentMethod, updatePaymentMethod } from '../../../services/payment.method.service'
import { MdDelete } from 'react-icons/md'
import { AppContext } from '../../../App'
import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

function PaymentMethod({ title = "", userData = null, pageNavigator = null, isNew = true, data = null, edit = false }) {
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [isDeleteCalled, setIsDeleteCalled] = useState(false);
  const [error, setError] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    document.title = "Select Payment Method | Renter | Ando";
    setPaymentDetails(data);
    if (!isNew && edit) {
      if (data?.payment_type == "account") {
        setPaymentMethod("A");
      }
      else if (data?.payment_type == "card") {
        setPaymentMethod("B");
      }
    }
  }, []);

  const cancel = () => {
    if (!isNew && edit) {
      setPaymentMethod(null);
      pageNavigator("ManageAccount");
    }
    else {
      setPaymentMethod(null)
    }
  }

  const saveRenterPaymentDetails = async (e) => {
    e.preventDefault();
    let paymentType = e.target.attributes.getNamedItem("data-target").value;

    let messageDiv = document.getElementsByClassName("message").item(0);

    let dataToSend = {
      detail: null,
      isPrimary: e.target?.isPrimary?.checked ?? true,
      payment_type: paymentType,
      token_type: null,
      status: "Active",
      user_id: userData?.data?.id,
      isForRenter: true
    }
    let goAHead = false;
    if (dataToSend.payment_type == 'account') {
      if (e.target.confirmAccountNumber.value != e.target.accountNumber.value) {
        return FormException(e, "Account Number not maching to confirm account field", messageDiv);
      }
      dataToSend.detail = {
        accountNumber: e.target.accountNumber.value,
        routingNumber: e.target.routingNumber.value,
        accountNickname: e.target.accountNickname.value,
      }
      dataToSend.token_type = e.target.accountNumber.value;
    }
    else if (dataToSend.payment_type == 'card') {
      // if (e.target.cardNumber.value?.length < 13 || e.target.cardNumber.value?.length > 17) return FormException(e, "Invalid Card Number", messageDiv);
      const cardNumberElement = elements.getElement(CardNumberElement)
      const cardExpiryElement = elements.getElement(CardExpiryElement)
      const cardCVCElement = elements.getElement(CardCvcElement)
      const cardElement = elements.getElement(CardElement)
      if(cardCVCElement && cardExpiryElement && cardNumberElement){
        const {paymentMethod, error} = await stripe.createPaymentMethod({
          type : 'card',
          billing_details : {
            name : e.target.cardOwnerName.value
          },
          card : cardNumberElement,
          
        })
        dataToSend.detail = {
          cardOwnerName: e.target.cardOwnerName.value
        }
        dataToSend.token_type = paymentMethod?.id;
        goAHead = paymentMethod ? true : false;
      }      
    }

    if (!isNew && edit) {
      updatePaymentMethod(paymentDetails?.id, dataToSend)
        .then((resp) => {
          if (resp.status) {
            setPaymentMethod(null);
            pageNavigator("ManageAccount");
          }
          else {
            return FormException(null, resp.message, messageDiv);
          }
        })
    }
    else {      
      dataToSend.detail = btoa(btoa(JSON.stringify(dataToSend.detail)))      
      const resp = await addPaymentMethod(dataToSend)
      if(resp?.status){
        setPaymentMethod(null);
        pageNavigator("ManageAccount");
      }
      else {
        return FormException(null, resp.message, messageDiv);
      }
    }
    messageDiv.style.display = "none"
  }

  const deleteAccountMethod = () => {
    let messageDiv = document.getElementsByClassName("message").item(0);
    deletePaymentMethod(paymentDetails?.id)
      .then((resp) => {
        if (resp.status) {
          setPaymentDetails(null);
          pageNavigator("ManageAccount");
        }
        else {
          deleteAccountConfirm(false)
          if (resp.message.includes("Cannot")) {
            setError(true)
          }
          else {
            return FormException(null, resp.message, messageDiv);
          }
        }
      })
  }

  const handleInputChange = (e) => {
    let newData = {
      detail: null,
      isPrimary: false
    };
    if (paymentMethod == 'A') {
      newData.detail = {
        routingNumber: "",
        accountNickname: "",
        accountNumber: ""
      }
    }
    else {
      newData.detail = {
        cardOwnerName: "",
        cardNumber: "",
        expiryMonth: "",
        expiryYear: "",
      }
    }

    if (paymentDetails != null) {
      newData = Object.create(paymentDetails);
    }

    switch (e.target.name) {
      case "routingNumber":
        if (!isNew)
          newData.detail.routingNumber = e.target.value
        break;

      case "accountNumber":
        if (!isNew)
          newData.detail.accountNumber = e.target.value
        break;

      case "accountNickname":
        if (!isNew)
          newData.detail.accountNickname = e.target.value
        break;

      case "cardOwnerName":
        if (!isNew)
          newData.detail.cardOwnerName = e.target.value
        break;

      case "cardNumber":
        if (!isNew)
          newData.detail.cardNumber = e.target.value
        break;

      case "expiryMonth":
        if (!isNew)
          newData.detail.expiryMonth = e.target.value
        break;

      case "expiryYear":
        if (!isNew)
          newData.detail.expiryYear = e.target.value
        break;
      case "isPrimary":
        // if(!isNew)
        newData.isPrimary = e.target.checked
        break;

    }
    setPaymentDetails(null);
    setPaymentDetails(newData)
  }

  const deleteAccountConfirm = (called) => {
    setIsDeleteCalled(called);
  }
  const yearsGenerate = () => {
    let date = new Date();
    let data = [];
    for (let i = 0; i < 25; i++) {
      data.push(<option>{date.getFullYear() + i}</option>)
    }
    return data;
  }
  return (
    <CustomWrapper
      width='100%'
      maxWidth={2000}
      height='600px'
      maxHeight={"100%"}
      paddingHorizontal={30}
      paddingVertical={30}
    >
      <div className='paymentOptionContainer'>
        <div className='title'>{title}</div>
        <div className='columnFormInput'>
          <label className='formRadio'>
            <input type='radio' name='paymentMethod' value={"A"} onClick={() => { setPaymentMethod("A") }} />
            <span>Checking Account</span>
          </label>
          <label className='formRadio'>
            <input type='radio' name='paymentMethod' value={"B"} onClick={() => { setPaymentMethod("B") }} />
            <span>Credit or Debit</span>
          </label>
        </div>
      </div>
      {
        paymentMethod === "A" &&
        <PopupWrapper onClose={cancel}>
          <div className='title'>Add Personal Checking Account</div>
          <form onSubmit={saveRenterPaymentDetails} data-target='account'>
            <table className='formTable'>
              <tr>
                <td>Name on Account</td>
                <td>
                  <div className='formInput'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name="accountNickname"
                      value={isNew ? null : paymentDetails?.detail.accountNickname}
                      required />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Bank Routing Number</td>
                <td>
                  <div className='formInput'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name="routingNumber"
                      value={isNew ? null : paymentDetails?.detail.routingNumber}
                      required />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Checking Account Number</td>
                <td>
                  <div className='formInput'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name="accountNumber"
                      value={isNew ? null : paymentDetails?.detail.accountNumber}
                      required />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Confirm Checking Account Number</td>
                <td>
                  <div className='formInput'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name="confirmAccountNumber"
                      required />
                  </div>
                </td>
              </tr>
            </table>
            <div className='rowFormInput'>
              {
                ((!isNew && edit) || (isNew && edit)) &&
                <label className='accountMinButton'>
                  <input type='checkbox' name='isPrimary' onChange={handleInputChange} checked={paymentDetails?.isPrimary} />
                  <span>Set as primary acccount</span>
                </label>
              }
            </div>
            <div className='message'>Sample Text</div>
            <div className="referenceImage">
              <img src={image} alt='Reference' />
            </div>
            {
              (!isNew && edit) &&
              <label className='accountMinButton' onClick={() => deleteAccountConfirm(true)}>
                <MdDelete color='red' size={15} />
                <label style={{ color: 'red' }}>Delete Account</label>
              </label>
            }
            <div className='buttonContainer'>
              <Button
                className='minButton'
                margin='-5px'
                type='button'
                primary={false}
                onPressFunction={cancel}
              >Cancel</Button>

              <Button
                className='minButton'
                margin='-5px'
                type='submit'
              >Submit</Button>
            </div>
          </form>
        </PopupWrapper>
      }
      {
        paymentMethod === "B" &&
        <PopupWrapper onClose={cancel}>
          <div className='title'>Add a Credit or Debit Card</div>
          <form onSubmit={saveRenterPaymentDetails} data-target='card'>
            <table className='formTable'>
              <tr>
                <td>Name on Card</td>
                <td>
                  <div className='formInput'>
                    <input
                      onChange={handleInputChange}
                      type='text'
                      name="cardOwnerName"
                      value={isNew ? null : paymentDetails?.detail.cardOwnerName}
                      required />
                  </div>
                </td>
              </tr>
              <tr>
                <td>Card Number</td>
                <td>
                  {/* <CardElement options={{placeholder : "Enter Card Number", showIcon : true}} className='formInputStripe'/> */}
                  <div className='formInput'>
                    <CardNumberElement options={{placeholder : "Enter Card Number", showIcon : true}} className='fullWidth formInputStripe'/>
                    {/* <input 
                      onChange={handleInputChange} 
                      type='text' 
                      name="cardNumber" 
                      value={isNew ? null : paymentDetails?.detail.cardNumber} 
                      required/> */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>CVV</td>
                <td>
                  <div className='formInput'>
                    <CardCvcElement options={{placeholder : 'Enter CVC'}}  className='formInputStripe'/>
                    {/* <input 
                      type='password' 
                      style={{maxWidth : 150, minWidth : 'auto', marginLeft : 0}} 
                      name="cvv" 
                      required/> */}
                  </div>
                </td>
              </tr>
              <tr>
                <td>Expiry Date</td>
                <td>
                  <div className='formInput'>
                    <CardExpiryElement options={{placeholder : 'Enter Card Expiry'}}  className='formInputStripe'/>
                    {/* <select 
                      onChange={handleInputChange} 
                      name="expiryMonth" 
                      value={isNew ? null : paymentDetails?.detail.expiryMonth} 
                      style={{ flex : 'auto', maxWidth : 80, minWidth : 'auto',margin: 'unset',marginLeft : 0,}}>
                      <option>01</option>
                      <option>02</option>
                      <option>03</option>
                      <option>04</option>
                      <option>05</option>
                      <option>06</option>
                      <option>07</option>
                      <option>08</option>
                      <option>09</option>
                      <option>10</option>
                      <option>11</option>
                      <option>12</option>
                    </select>
                    <select 
                      onChange={handleInputChange} 
                      name="expiryYear" 
                      value={isNew ? null : paymentDetails?.detail.expiryYear} 
                      style={{flex : 'auto', maxWidth : 150, minWidth : 'auto',margin: 'unset', marginLeft : 0}}>
                        {yearsGenerate()}
                    </select> */}
                  </div>
                </td>
              </tr>
            </table>
            <div className='rowFormInput'>
              {
                ((!isNew && edit) || (isNew && edit)) &&
                <label className='accountMinButton'>
                  <input type='checkbox' name='isPrimary' onChange={handleInputChange} checked={paymentDetails?.isPrimary} />
                  <span>Set as primary acccount</span>
                </label>
              }
            </div>
            <div className='message'>Sample Text</div>
            <div className="referenceImage" style={{ visibility: 'hidden' }}>
              <img src={image} alt='Reference' />
            </div>
            {
              (!isNew && edit) &&
              <label className='accountMinButton' onClick={() => deleteAccountConfirm(true)}>
                <MdDelete color='red' size={15} />
                <label style={{ color: 'red' }}>Delete Account</label>
              </label>
            }
            <div className='buttonContainer'>
              <Button
                className='minButton'
                margin='-5px'
                type='button'
                primary={false}
                onPressFunction={cancel}
              >Cancel</Button>

              <Button
                className='minButton'
                margin='-5px'
                type='submit'
              >Submit</Button>
            </div>
          </form>
        </PopupWrapper>
      }
      {
        isDeleteCalled &&
        <PopupWrapper onClose={() => { deleteAccountConfirm(false) }}>
          <div style={{ fontSize: 16, textAlign: 'center' }}>
            Are you sure you want to delete this account?
          </div>
          <div style={{ width: 'fit-content', display: 'flex' }}>
            <Button
              className='minButton'
              type='button'
              primary={false}
              onPressFunction={() => { deleteAccountConfirm(false) }}
            >Cancel</Button>
            <Button
              className='minButton'
              type='submit'
              primary={true}
              onPressFunction={deleteAccountMethod}
            >Delete</Button>
          </div>
        </PopupWrapper>
      }
      {
        error && <PopupWrapper maxWidth={500} onClose={() => { setError(false) }}>
          <div style={{ fontSize: 16, textAlign: 'left', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <img src={ErrorPng} height={70} />
            Looks like this is your primary account to send payments, please add other primary account before you delete this.
          </div>
        </PopupWrapper>
      }
    </CustomWrapper>
  )
}

export default PaymentMethod