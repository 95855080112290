import React from 'react'
import "./MiniCheckbox.css";

function MiniCheckbox({
    name = "",
    label = "",
    isChecked = false,
    onChange,
    fontSize = 10,
    labelStyle = null
}) {
  let id = (Math.random() * 100000)?.toString()?.split(".")[0]
  return (
    <div className='miniCheckboxCustom'>
        <input type='checkbox' checked={isChecked} id={`${name}_${id}`} style={{background : isChecked ? "#526035" : "#EAEAEA"}} onChange={onChange} name={name} />
        <label htmlFor={`${name}_${id}`} style={{fontSize : fontSize, ...labelStyle}}>{label}</label>
        {
          isChecked && 
          <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="none"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              onClick={() => {
                onChange({
                  target : {
                    checked : !isChecked
                  }
                })
              }}
          >
              <polyline points="20 6 9 17 4 12"></polyline>
          </svg>
        }
    </div>
  )
}

export default MiniCheckbox