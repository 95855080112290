import React, { useState,useEffect, useContext } from 'react'
import CustomWrapper from '../../wrapper/CustomWrapper'
import "./PaymentDashboard.css"
import Button from '../../Button/Button'
import HighlighterButton from '../../Button/HighlighterButton'
import SmallNote from '../../note/SmallNote'
import CustomTable from '../../table/CustomTable'
import { SampleTableData } from '../../table/SampleTableData'
import Calendar from '../../Calendar/Calendar'
import PopupWrapper from "../../wrapper/PopupWrapper"
import { isLoggedIn } from '../../../utility/util'
import { getRenterDues, loadRenterDetails } from '../../../services/property.service'
import { createNewTransactionForUser, getAllTrancationsForUser } from '../../../services/payment.method.service'
import { FormException } from '../../../utility/exceptions'
import { AppContext } from '../../../App'
import HatTrophy from "../../../assets/hat.png"
import { useNavigate } from 'react-router-dom'
import Checkbox from '../../checkbox/Checkbox'
import { Loader, MiniLoader } from '../../Loader/Loader'

function PaymentDashboard({userData = null, paymentAccounts=null}) {
  const [isOneTimePayment, setIsOneTimePayment] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [stepTitle, setStepTitle] = useState("Make a one-time payment.");
  const [paymentAccountList, setPaymentAccountList] = useState(null);
  const [dueDate, setDueDate] = useState(new Date());
  const [pendingAmount, setPendingAmount] = useState(0);
  const [customTableData, setCustomTableData] = useState([]);
  const [penalty, setPenalty] = useState(0);
  const navigate = useNavigate();
  const [calendarD, setCalanderD] = useState(new Date())
  const appContext = useContext(AppContext);
  const [rentPaid, setRentPaid] = useState(false)
  const [rentPaidAmount, setRentPaidAmount] = useState(0)
  const [rentMonthList, setRentMonthList] = useState([]);
  const [payType, setPayType] = useState("full");
  const [paidMonths, setPaidMonths] = useState("");

  useEffect(() => {
    document.title = "Payment Details | Renter | Ando";    
    let uInfo = isLoggedIn();
    if (userData == null) {
      userData = uInfo;
    }
    loadDues();
  }, []);

  useEffect(() =>{
    let accountList =[];
    paymentAccounts?.forEach((acc) => {
      
      accountList.push(
        <PaymentBox payment_token_id={acc.id} paymentType={acc.payment_type} paymentDetails={acc.detail} primary={acc?.isPrimary}/>
      )
    })
    setPaymentAccountList([...accountList]);
  }, [paymentAccounts])

  const loadDues = () => {
    setLoading(true)
    getRenterDues(userData?.data?.id)
    .then((duesResp) =>{
      setLoading(false)
      if(duesResp?.length > 0){
        duesResp[0].penalty = new Date()?.getDate() <= 16 ? 0 : duesResp[0].penalty;
        setDueDate(
          new Date(`${duesResp?.at(0)?.year}-${String(duesResp?.at(0)?.month)?.padStart(2, "0")}-01`)
        )
        setPenalty(duesResp?.at(0)?.penalty ?? 0);
        let pendingAmountSum = 0;
        duesResp?.forEach((item) => {
          pendingAmountSum += item?.rent + (item?.penalty ?? 0)
        })
        setPendingAmount(pendingAmountSum)
        setRentMonthList(duesResp?.map((e) => ({...e, isChecked : false})))
      }
      else{
        setPendingAmount(0)
      }
    })
    .then(() => {      
      fetchTransactions();
    })
    .catch((error) =>{
      setLoading(false)
    })
  }
  const fetchTransactions = () => {
    setLoading(true)
    getAllTrancationsForUser(userData?.data?.id)
    .then((resp) => {
      setLoading(false)
      let amountPaidTillDate = 0;
      if(resp.status){
        let customData = [];
        resp.data?.forEach((element) => { 
          amountPaidTillDate += parseInt(element.amount);
          let paymentDate = new Date(element.created_at);
          let rentFor = new Date(element?.transactionDetails?.rentPeriod)
          rentFor.setDate(1)
          let paymentStatus = paymentDate > rentFor ? "Late" : "On Time";
          let data = {
            "Payment Date" : `${paymentDate.getDate()} ${paymentDate.toLocaleString("default", { month: "long" })}, ${paymentDate.getFullYear()}`,
            "Amount" : "$" + element.amount,
            "Rent Period" : `${element?.transactionDetails?.rentPeriod}`,
            "Status" : paymentStatus,
            "Balance" : "$" + (element.transactionDetails.balance == null ? 0 : element.transactionDetails.balance),
          }
          customData.push(data)
        })
        setCustomTableData([...customData]);
      }   
    })
  }

  const performOneTimePayment = ()=>{
    if(pendingAmount > 0)
      setIsOneTimePayment(true);
    else{
      appContext.toastRef.current.launch("Rent already paid");
    }
  }

  const onClose = ()=>{
    setIsOneTimePayment(false);
  }

  const payRent = (e) => {
    e.preventDefault();
    setLoading(true)
    let messageDiv = document.getElementsByClassName("message").item(0);

    let paymentAmountType = e.target.paymentAmountSelect.value;
    let rentTobePaid = rentMonthList;
    if(paymentAmountType.trim().length == 0){
      setLoading(false)
      return FormException(e, "Select valid rent amount", messageDiv);
    }

    if(paymentAmountType == 'other'){
      let pendingAmountSum = rentMonthList?.filter((item) => item?.isChecked);
      
      if(pendingAmountSum?.length == 0){
        setLoading(false)
        return FormException(e, "Select Any from Rent Timeline", messageDiv);
      }
      else{
        rentTobePaid = pendingAmountSum
      }
    }

    let paymentType = e.target.paymentType.value;

    if(paymentType == null || paymentType == undefined || paymentType.trim().length == 0){
      setLoading(false)
      return FormException(e, "Select valid payment method", messageDiv);
    }
    rentTobePaid = rentTobePaid?.sort((item1, item2) =>{
      if(item1?.year !== item2?.year)
        return item1?.year - item2?.year;
      return item1?.month-item2?.month
    })    
    createNewTransactionForUser({
      amount : rentTobePaid,
      payment_token_id : paymentType,
      user_id : userData?.data?.id,
    })
    .then((resp) => {
      setLoading(false)
      let totalAmount = 0;
      rentTobePaid?.forEach((item) =>{
        totalAmount += item?.rent + (item?.penalty ?? 0)
      })
      setRentPaidAmount(isNaN(totalAmount) ? 0 : totalAmount);
      onClose()
      if(!resp?.status){
        appContext.toastRef.current.launch(resp?.message)
      }
      else{
        let t = rentTobePaid?.filter((e) => payType == 'full' ? true : e?.isChecked)?.map((e) => {
          let temp = new Date(`${e?.year}-${String(e?.month)?.padStart(2, "0")}-01`)
          return `${temp.toLocaleString("default", {month : "long"})} ${temp.getFullYear()}`
        })
        console.log(t);
        
        setPaidMonths(t.join(", "))
        setRentPaid(true)
        loadDues();
        fetchTransactions()
      }
    })
    messageDiv.style.display = "none"
  }
  const setupAutopay = () => {
    appContext.toastRef.current.launch("Feature is in maintainence");
  }

  const daysCalculator = () => {
    let temp = Math.floor((new Date(
      dueDate.getFullYear(),
      dueDate.getMonth(),
      dueDate.getDate() + 15
      ) - new Date()) / 1000 / 60 / 60 / 24)
    return temp > 0 ? temp : 0
  }
  return (
    <>
    
      <CustomWrapper
        width='auto'
        maxWidth={"auto"}
        height='100%'
        marginHorizontal='50px'
        marginVertical='10px'
        paddingHorizontal={30}
        paddingVertical={30}
      >
        <div className='paymentDashboard'>
          <div className='infoContainer'>
            <div className='balanceInfo'>
              <div className='leftDiv'>
                <span>Current Balance : </span>
                {
                  pendingAmount > 0 && 
                  <p>Due {dueDate.toLocaleString("en-gb", { month: "long"})} {dueDate.toLocaleString('en-gb', {day : 'numeric'})}, {dueDate.toLocaleString('en-gb', {year : 'numeric'})}</p>
                }
              </div>
              <div className='rightDiv'>
                {
                  isLoading && <MiniLoader />
                }
                {
                  !isLoading && 
                  <span>${pendingAmount}</span>
                }
              </div>
            </div>
            <div className='infoButtonContainer'>
              <Button className='minButton infoButton'
              onPressFunction={performOneTimePayment}>
                Make a One Time Payment
              </Button>
              <HighlighterButton onPressFunction={setupAutopay} className='minButton infoButton' fontSize='15px'>
                Set up auto pay
              </HighlighterButton>
            </div>
          </div>
          {
            pendingAmount > 0 && (dueDate - new Date()) < 0 &&
            <SmallNote><b>Friendly Reminder : </b> You have {daysCalculator()} days left in your grace period. To avoid late fee of ${isNaN(penalty) ? 0 : penalty} please make your payment on or before {new Date(
                dueDate.getFullYear(),
                dueDate.getMonth(),
                dueDate.getDate() + 15
                ).toDateString()}.</SmallNote>
          }
          <CustomTable
            title={"Payment Transactions"}
            json={customTableData}
          />
        </div>
        {isOneTimePayment && (
          <form onSubmit={payRent}>
          <PopupWrapper maxWidth={"auto"} height="90%" overflowY="auto" onClose={onClose}>
            <div className="OnTimePaymentTitle"> {stepTitle}</div>
            <h5 className="header5">Select payment date.</h5>
          <Calendar 
            currentDate={calendarD}
            setCurrentDate={setCalanderD}
            dueDate={dueDate}
            amount={penalty}
            expDate={
              new Date(
                dueDate.getFullYear(),
                dueDate.getMonth(),
                dueDate.getDate() + 15
                )
              }/>
            <br />
            <hr className="hrLine" />
            <h5 className="header5">Select payment amount.</h5>
            <div style={{display :'flex', flexDirection : 'column', gap : 15}}>
              <div className="rowPaymentAmount" style={{flexDirection : 'row'}}>
                <div>
                  <label className="paymentRadio">
                    <input 
                      type="radio" 
                      checked={payType === "full"} 
                      name="paymentAmountSelect" 
                      value="fixed" 
                      onChange={() => {
                        setPayType("full")
                        setRentMonthList(rentMonthList?.map((item) => {
                          return {
                            ...item,
                            isChecked : false
                          }
                        }))
                      }}
                    />
                    <p>Pay Balance</p>
                  </label>
                </div>
                <div>
                  <input type="hidden" name={"fixedAmount"} value={pendingAmount}/>
                  <div className="Amount" style={{textAlign : "start"}}>${pendingAmount}</div>
                </div>
              </div>
              <div className="rowPaymentAmount">
                <div>
                  <label className="paymentRadio">
                    <input type="radio" name="paymentAmountSelect" value="other" checked={payType === "other"} onChange={() => setPayType("other")} />
                    <p>Pay Other Amount</p>
                  </label>
                </div>
                <div style={{
                    marginTop : -10, 
                    marginLeft : 10, 
                    display : 'flex', 
                    flexDirection : 'row', 
                    gap : 10, 
                    width : '100%', 
                    overflowX : 'auto', 
                    background : (payType !== "other") ? "rgba(0,0,0,0.05)" : "#FFFFFF"
                  }} 
                  className='scroller'>
                  {
                    rentMonthList?.map((item, index) => {
                      let date = new Date(`${item?.year}-${String(item?.month)?.padStart(2, "0")}-01`)
                      return (
                          <Checkbox
                            key={index}
                            name={"rentPayMonths"+index}
                            label={`${date?.toLocaleString("default", {month : "short"})} ${date?.getFullYear()} - $${item?.rent + (item?.penalty ?? 0)}`}
                            isChecked={item?.isChecked}
                            onChange={() => {
                              if(payType === "other"){
                                setRentMonthList([...rentMonthList?.map((e, i) => {
                                  if(i === index){
                                    return {
                                      ...e,
                                      isChecked : !e?.isChecked
                                    }
                                  }
                                  return e;
                                })])
                              }
                            }}
                            labelStyle={{textWrap : 'nowrap', fontSize : 12, color : "rgba(0,0,0,0.7)"}}
                          />
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <br />
            <hr className="hrLine" />
            <h5 className="header5">Select payment method.</h5>
            <div className="PaymentMethod-Container">
              {paymentAccountList}
              <div className="paymentMethod">
                <div>
                  <label className="paymentRadio" onClick={() => {navigate("/renter/payment/account/manage")}}>
                    <input type="radio" name="paymentType" value={null}/>
                    <span>Pay with a different method</span>
                  </label>
                </div>
              </div>
            </div>
            <div className='message' style={{marginTop : 30, marginBottom : -50}}>Sample Text</div>
            <div className="PayRent">
              <Button
                primary={true}
                margin="0"
                paddingVertical="8px"
                type="submit"
                className="btnPayRent"
              >
                Pay Rent
              </Button>
            </div>
          </PopupWrapper>
        </form>
        )
        }
      </CustomWrapper>
      {
        rentPaid &&
        <PopupWrapper onClose={() => {setRentPaid(false)}} maxWidth={550}>
          <div style={{display : "flex", flexDirection : "row", gap : 5, alignItems : "center"}}>
            <img src={HatTrophy} alt='' height={100}/>
            <div style={{maxWidth : 400}}>Rad! You’ve paid ${isNaN(rentPaidAmount) ? 0 : rentPaidAmount} in rent ON TIME for {paidMonths}. 
              We posted it to your account on {(new Date())?.toLocaleString("default", {month : "long"})} {(new Date()).getDate()}, {calendarD.getFullYear()}. </div>
          </div>
        </PopupWrapper>
      }
      {
        isLoading && <Loader />
      }
    </>
  )
}

const PaymentBox = ({payment_token_id, paymentType, paymentDetails, primary = false}) => {
  return (
    <div className="paymentMethod">
      {
        paymentType == 'card' &&
        <>
          <div>
            <label className="paymentRadio">
              <input type="radio" name="paymentType" value={payment_token_id} checked={primary}/>
              <div className='paymentModeName'>
                {paymentDetails.cardOwnerName}
                <p>{paymentType}</p>
              </div>
              <span>*****{paymentDetails.cardNumber.substring(paymentDetails.cardNumber.length - 4)}</span>
            </label>
          </div>
          <div className="expDate"> Exp {paymentDetails.expiryMonth}/{paymentDetails.expiryYear}</div>
        </>

      }
      {
        paymentType == "account" &&
        <>
          <div>
            <label className="paymentRadio">
              <input type="radio" name="paymentType" value={payment_token_id} checked={primary}/>
              <div className='paymentModeName'>
                {paymentDetails.accountNickname}
                <p>{paymentType}</p>
              </div>
              <span>*****{paymentDetails.accountNumber.substring(paymentDetails.accountNumber.length - 4)}</span>
            </label>
          </div>
          <div className="expDate"> Routing No. {paymentDetails.routingNumber.substring(paymentDetails.routingNumber.length - 4)}</div>
        </>
      }
    </div>
  )
}

export default PaymentDashboard