import React, { useEffect, useState } from 'react'
import "./CustomTable.css"
import Button from '../Button/Button'
import { MdDownload, MdFilter, MdFilterAlt } from 'react-icons/md'
import StatusLabel from '../Status/StatusLabel'
import Checkbox from '../checkbox/Checkbox'
import MiniCheckbox from '../checkbox/MiniCheckbox'

function CustomTable({ title, json, isDownloadAllowed = true }) {

    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState([])
    const [showFilter, setShowFilter] = useState(null)
    const [filters, setFilters] = useState(null);

    const loadTable = (jsonObject) => {
        if (jsonObject.length > 0) {
            let jsonHeader = Object.keys(jsonObject[0]);
            setHeaders(jsonHeader)
            setRows(jsonObject)
            let tempFilters = {}
            for (let head of jsonHeader) {
                tempFilters[head] = {
                    type: (
                        head?.toLowerCase()?.includes('property') ||
                        head?.toLowerCase()?.includes('tenant')
                    ) ? 'text' : (
                        head?.toLowerCase()?.includes('amount') ||
                        head?.toLowerCase()?.includes('balance')
                    ) ? 'range' : 'list',
                    value: (
                        head?.toLowerCase()?.includes('property') ||
                        head?.toLowerCase()?.includes('tenant')
                    ) ? '' : (
                        head?.toLowerCase()?.includes('amount') ||
                        head?.toLowerCase()?.includes('balance')
                    ) ? 0 : []
                }
            }
            setFilters(tempFilters);
        }
    }

    useEffect(() => {
        loadTable(json)
    }, [json])

    const downloadTable = () => {
        window.print()
    }
    const handleOnBlur = (event) => {
        if (event.currentTarget.contains(event.relatedTarget)) {
            return;
        }
        setShowFilter(null)
    }
    useEffect(() => {
        if (filters) {
            setRows(json?.filter((item) => {
                let header = Object.keys(item);
                let itemFound = true;
                for (let e of header) {
                    if (filters[e]?.value?.length > 0) {
                        if (filters[e]?.type == 'text') {
                            if (!extractString(item[e])?.toLowerCase()?.includes(filters[e]?.value?.toLowerCase())) {
                                itemFound = false;
                                break;
                            }
                        }
                        else if (filters[e]?.type == 'list') {
                            if (!filters[e]?.value?.includes(item[e])) {
                                itemFound = false;
                                break;
                            }
                        }
                        else if (filters[e]?.type == 'range') {
                            let num = item[e]?.split("$")
                            let temp = parseInt(num?.length > 1 ? num[1] : num[0]);
                            if (temp < filters[e]?.value) {
                                itemFound = false;
                                break;
                            }
                        }
                    }
                }
                return itemFound
            }))
        }
    }, [filters])

    const extractString = (obj) => {
        if (typeof obj === 'string') return obj;
        else if (React.isValidElement(obj)) {
            return extractString(obj?.props.children);
        } else if (Array.isArray(obj)) {
            return obj.map(e => extractString(e)).join(' ');
        } else return obj.toString();
    }


    const toggleToFilter = (key, value) => {
        setFilters({
            ...filters,
            [key]: {
                ...filters[key],
                value: filters[key]?.value?.includes(value) ? filters[key]?.value?.filter((e) => e != value) : [...filters[key]?.value, value]
            }
        })
    }

    const minRange = (item) => {
        let min = 0;
        json.map((e) => {
            let num = e[item]?.split("$")
            let temp = parseInt(num?.length > 1 ? num[1] : num[0]);
            min = min < temp ? min : temp;
        })
        return min;
    }
    
    const maxRange = (item) => {
        let max = 0;
        json.map((e) => {
            let num = e[item]?.split("$")
            let temp = parseInt(num?.length > 1 ? num[1] : num[0]);
            max = max > temp ? max : temp;
        })
        return max;
    }
    
    return (
        <div className='mainTableContainer' id="printablediv">
            <div className='tableHeader'>
                <div className='tableTitle'>{title}</div>
                <Button
                    margin={"0px"}
                    primary={false}
                    className='minButton tableButton'
                    onPressFunction={downloadTable}
                ><MdDownload size={18} /> Download</Button>
            </div>
            <div className='onlyTableDiv scroller'>
                <table className='table'>
                    <thead>
                        <tr>
                            {
                                headers?.map((item, ind) => {
                                    return (
                                        <th key={`table-custom-head-${ind}`} onBlur={handleOnBlur}>
                                            <div style={{ display: 'flex', flexDirection: 'row', gap: "10px", alignItems: 'center' }} >
                                                {item}
                                                <span style={{ position: 'relative' }} tabIndex={1} onClick={(e) => { e.stopPropagation(); setShowFilter(ind) }}>
                                                    <MdFilterAlt size={13} color='rgba(0,0,0,0.5)' />
                                                    {
                                                        showFilter == ind && filters[item]?.type == 'text' && (
                                                            <div
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                style={{
                                                                    position: 'absolute',
                                                                    padding: '8px',
                                                                    background: '#E9E9E9',
                                                                    borderRadius: '5px',
                                                                    left: '0px',
                                                                    top: '10px',
                                                                    border: '1px solid #C4C4C4',
                                                                    zIndex: 10
                                                                }}
                                                            >
                                                                <input
                                                                    type='text'
                                                                    onMouseDown={(e) => e.stopPropagation()}
                                                                    placeholder='Search...'
                                                                    value={filters[item]?.value}
                                                                    style={{
                                                                        minWidth: '230px',
                                                                        outline: 'none',
                                                                        margin: '1px 0px',
                                                                        padding: '6px',
                                                                        fontSize: '10px',
                                                                        fontFamily: 'Poppins',
                                                                        background: '#FFFFFF',
                                                                        borderRadius: '5px',
                                                                        border: 'none'
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setFilters({
                                                                            ...filters,
                                                                            [item]: {
                                                                                ...filters[item],
                                                                                value: e?.target?.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        showFilter == ind && filters[item]?.type == 'range' && (
                                                            <div
                                                                onMouseDown={(e) => e.stopPropagation()}
                                                                style={{
                                                                    position: 'absolute',
                                                                    padding: '8px',
                                                                    background: '#E9E9E9',
                                                                    borderRadius: '5px',
                                                                    left: '-80px',
                                                                    top: '10px',
                                                                    border: '1px solid #C4C4C4',
                                                                    zIndex: 10,
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                }}
                                                            >
                                                                <input
                                                                    type='range'
                                                                    onMouseDown={(e) => e.stopPropagation()}
                                                                    value={filters[item]?.value}
                                                                    min={minRange(item)}
                                                                    max={maxRange(item)}
                                                                    step={1}
                                                                    style={{
                                                                        minWidth: '230px',
                                                                        outline: 'none',
                                                                        margin: '1px 0px',
                                                                        padding: '6px',
                                                                        fontSize: '10px',
                                                                        fontFamily: 'Poppins',
                                                                        background: '#FFFFFF',
                                                                        borderRadius: '5px',
                                                                        border: 'none'
                                                                    }}
                                                                    onChange={(e) => {
                                                                        setFilters({
                                                                            ...filters,
                                                                            [item]: {
                                                                                ...filters[item],
                                                                                value: e?.target?.value
                                                                            }
                                                                        })
                                                                    }}
                                                                />
                                                                <div
                                                                    style={{
                                                                        display : 'flex',
                                                                        flexDirection : 'row',
                                                                        justifyContent : 'space-between'
                                                                    }}
                                                                >
                                                                    <label>${minRange(item)}</label>
                                                                    <label>${filters[item]?.value}</label>
                                                                    <label>${maxRange(item)}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        showFilter == ind && filters[item]?.type == 'list' &&
                                                        <div
                                                            onMouseDown={(e) => e.stopPropagation()}
                                                            style={{
                                                                position: 'absolute',
                                                                background: '#E9E9E9',
                                                                borderRadius: '5px',
                                                                left: '-80px',
                                                                top: '10px',
                                                                border: '1px solid #C4C4C4',
                                                                zIndex: 10,
                                                                maxHeight: '100px',
                                                                overflowY: 'auto',
                                                                minWidth: '150px'
                                                            }}
                                                            className='scroller'
                                                        >
                                                            {
                                                                Array?.from(new Set(json?.map((e) => e[item])))?.map((e) => {
                                                                    if (filters[item]?.value?.includes(e)) {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    background: "#cdd9b6",
                                                                                    color: 'rgba(0,0,0,0.95)',
                                                                                    padding: '8px 12px',
                                                                                    minWidth: '75px',
                                                                                    fontSize: '10px',
                                                                                    textAlign: 'center',
                                                                                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                                                                                    display : 'flex',
                                                                                    flexDirection : 'row',
                                                                                    gap : '2px',
                                                                                    alignItems : 'center'
                                                                                }}
                                                                                onMouseDown={(element) => {
                                                                                    element?.stopPropagation();
                                                                                    toggleToFilter(item, e)
                                                                                }}
                                                                            >
                                                                                <MiniCheckbox
                                                                                    onChange={(element) => {
                                                                                        element?.stopPropagation();
                                                                                    }}
                                                                                    isChecked={true}
                                                                                />
                                                                                <span style={{ fontWeight: "bold" }}>{e}</span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <div
                                                                                style={{
                                                                                    background: "#ffffff",
                                                                                    color: 'rgba(0,0,0,0.95)',
                                                                                    padding: '8px 12px',
                                                                                    minWidth: '75px',
                                                                                    fontSize: '10px',
                                                                                    textAlign: 'center',
                                                                                    borderBottom: '1px solid rgba(0,0,0,0.25)',
                                                                                    display : 'flex',
                                                                                    flexDirection : 'row',
                                                                                    gap : '2px',
                                                                                    alignItems : 'center'
                                                                                }}
                                                                                onMouseDown={(element) => {
                                                                                    element?.stopPropagation();
                                                                                    toggleToFilter(item, e)
                                                                                }}
                                                                            >
                                                                                <MiniCheckbox
                                                                                    isChecked={false}
                                                                                    onChange={(element) => {
                                                                                        element?.stopPropagation();
                                                                                    }}
                                                                                />
                                                                                <span>{e}</span>
                                                                            </div>
                                                                        )
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    }
                                                </span>
                                            </div>
                                        </th>
                                    )
                                })
                            }
                        </tr>
                    </thead>

                    {
                        rows?.length > 0 &&
                        <tbody>
                            {
                                rows?.map((row, index) => {
                                    return (
                                        <tr key={`table-custom-${index}`}>
                                            {
                                                headers?.map((key, i) => {
                                                    if (key.toLowerCase() != "status")
                                                        return (<td key={`table-custom-cell-${i}`}>{row[key]}</td>)
                                                    else {
                                                        return <td key={`table-custom-cell-${i}`} ><StatusLabel>{row[key]}</StatusLabel></td>
                                                    }
                                                })

                                            }
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    }
                    {
                        rows?.length == 0 &&
                        <tbody style={{ boxShadow: 'none' }}>
                            <tr>
                                <td style={{ textAlign: "center" }}>No Data Available</td>
                            </tr>
                        </tbody>
                    }
                </table>
            </div>

        </div>
    )
}

export default CustomTable